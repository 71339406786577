import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import { Alert, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
    const form = useRef();

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(
        {
            color: '',
            message: ''
        }
    )

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        await emailjs.sendForm('service_01x8nkl', 'template_11jfh0n', form.current, {
            publicKey: 'YVxL3SHlnk71kkaqG',
          })
          .then(
            () => {
                setAlert(
                    {
                      color: 'primary',
                      message: 'Message sent successfully!'  
                    }
                )
              console.log('SUCCESS!');
            },
            (error) => {
                setAlert(
                    {
                      color: 'danger',
                      message: 'There has been an error. Please try again.'  
                    }
                )
              console.log('FAILED...', error.text);
            },
          );

          setLoading(false)

            setTimeout(() => {
                setAlert({
                    color: '',
                    message: '',
                })
            }, 3000)
        
    }

    return (
        <>
        <form onSubmit={handleSubmit} ref={form} style={{ maxWidth: '40rem', width: 'auto' }}>
            {alert.message && (<><Alert key={alert.color}>{alert.message}</Alert></>)}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="John Doe" name="name" required  />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" name="email" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" placeholder="enter a message" rows={3} name="message" required />
        </Form.Group>
        <Button type='submit' value="Send" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
        </Button>
        </form>
        </>
    )
}
