// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRKjKTn1ZDQwYYdVLiu4M3JcUeEYOX3YI",
  authDomain: "genesis-dna-labs.firebaseapp.com",
  projectId: "genesis-dna-labs",
  storageBucket: "genesis-dna-labs.appspot.com",
  messagingSenderId: "540070172734",
  appId: "1:540070172734:web:fafc8ce8eec6a7a822996f",
  measurementId: "G-G6B1YT4NXF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);