import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import './index.css';

export default function Footer() {
  const url = "https://calendly.com/genesisdnalabs/appointment-form"

  return (
    <Navbar id='footer' className='mt-2'>
        <Container style={{flexDirection: 'column'}}  align='center'>
          <Navbar.Brand>
            <Link to='/' className='footer-nav-link'>
              <h5>Genesis DNA Labs</h5>
            </Link>
          </Navbar.Brand>
          <Nav style={{flexDirection: 'column'}}>
          <Nav.Link>
              <Link to='/termsandconditions' className='footer-nav-link'>Terms & Conditions</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to='/about' className='footer-nav-link'>About</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to='/services' className='footer-nav-link'>Services</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='/schedule' className='footer-nav-link'>Schedule</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to='/contact' className='footer-nav-link'>Contact</Link>
            </Nav.Link>
          </Nav>
          <a href="https://www.ddmwebdesigns.com" target="_blank" className='pt-2'>
            <p style={{fontSize: '12px', color: 'white'}}>Designed by DDM Web Designs</p>
          </a>
        </Container>
      </Navbar>
  )
}
