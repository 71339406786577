import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import image from "../assets/images/spare-3.jpg";

import SEOHelmet from "../components/seo-helmet/SEOHelmet";

export default function TermsAndConditions() {
  return (
    <Layout
      image={image}
      header={"Terms and Conditions"}
      subheader={""}
      content={
        <>
        <SEOHelmet 
                page="Terms and Conditions" 
                link="termsandconditions"
                content="View Genesis DNA Labs' various terms and conditions."
            />
          <Container>
            <div>
              <h3>Binding Arbitration:</h3>
              <br />
              These terms and conditions outline the procedures for resolving
              disputes between you and Genesis DNA Labs. In essence, they
              mandate that any claims you have against the Genesis DNA Labs must
              be resolved through binding, confidential, and final arbitration
              on an individual basis. This means you cannot bring your claims as
              part of a group, class, or representative action or proceeding.
              Please refer to Section 19 for detailed information on your
              agreement to arbitrate disputes with us.
            </div>
            <br />
            <br />
            <div>
              <h3>Cautions:</h3>
              <br />
              Various factors can affect the accuracy of the Service and its
              test results, including your ability to provide biological samples
              according to the instructions in the Kits without contamination.
              By using the Service, you acknowledge that we are not responsible
              for errors in testing or other services caused by your actions or
              failure to strictly follow Kit instructions when providing
              samples. We do not guarantee that the Service will be free of
              errors, accurate, or reliable, nor do we guarantee specific
              results or acceptance by any particular entity.
            </div>
            <br />
            <br />
            <div>
              <h3>Medical Advice Disclaimer:</h3>
              <br />
              The Service and the Site do not offer medical advice or guarantees
              regarding health or safety. If you believe you are experiencing a
              medical emergency, contact your doctor, 911, or local emergency
              services immediately. Your use of the Service, the Site, and any
              information generated by them is at your own risk. We do not
              provide medical advice, diagnoses, treatment recommendations, or
              engage in the practice of medicine. The content and materials
              provided by the Genesis DNA Labs on the Site are for informational
              purposes only and are not intended to replace professional medical
              advice, diagnosis, treatment, or public health information.
              Decisions regarding the need for medical services and the type of
              healthcare to be provided should be made by a physician or
              licensed healthcare provider. Always consult a physician or
              qualified healthcare provider with any questions about actual or
              potential medical conditions or symptoms you may be experiencing.
              For any assistance or inquiries related to the above, please
              contact us at genesisdnalabs@gmail.com.
            </div>
            <br />
            <br />
            <div>
              <h3>Certain Definitions:</h3>
              <br />
              Capitalized terms not defined where they first appear have the
              following meanings:
              <br />
              (a) "Conditions" refers to these terms and conditions.
              <br />
              (b) "Contract" pertains to any order submitted for the provision
              of Services by Genesis DNA Labs to you.
              <br />
              (c) "Kits" are sampling kits dispatched to you upon order receipt
              or purchased separately.
              <br />
              (d) "Request Form" signifies the standard service order form for
              the Service provided by Genesis DNA Labs on our website at
              dnacenter.com.
              <br />
              (e) "Service" encompasses various testing services provided by
              Genesis DNA Labs, including but not limited to paternity, DNA
              relationship and fertility testing, individual DNA-testing
              services related to ancestry, weight management, skin care,
              sensitivity to certain foods and pets, and DNA testing for
              animals, along with associated services and results.
              <br />
              (f) "Site" refers to dnacenter.com and any other Company-operated
              websites or online applications that incorporate or link to these
              Conditions.
              <br />
              (g) "You" denotes the individual ordering the Service and/or
              utilizing the Site.
            </div>
            <br />
            <br />
            <div>
              <h3>Terms of Sale:</h3>
              <br />
              (a) These Terms govern all content and information available on
              the Site and all Contracts, excluding any other terms and
              conditions, including those you may attempt to apply through a
              purchase order confirmation, Request Form, or similar document.
              <br />
              (b) By ordering the Service through the Site, either by completing
              the registration form and clicking the “Submit” button or through
              a representative, you are legally bound to have offered to
              purchase the Service under these Terms.
              <br />
              (c) All sales by Genesis DNA Labs are subject to these Terms. Any
              changes to these Terms must be confirmed in writing by a director
              of Genesis DNA Labs be binding. The Effective Date of these Terms
              is indicated at the top of this webpage. Genesis DNA Labs may
              modify these Terms at its discretion and will post any changes on
              the Site. We encourage you to check the Site regularly to stay
              informed about our current Terms. Your continued use of the Site
              after any changes constitutes your agreement to abide by the
              updated terms. The revised Terms supersede all previous versions.
              <br />
              (d) You are responsible for assessing whether the offered Service
              is suitable for your specific needs and the resulting
              consequences.
              <br />
              (e) The features and specifications of the Service described or
              depicted on the Site are subject to change without prior notice.
              <br />
              (f) These Terms do not affect your statutory rights as a consumer.
              <br />
              License
              <br />
              The EZ Mobile DNA grants you a limited license to access and use
              the Site for personal purposes, subject to these Terms. The Site
              or any part of it may not be reproduced, copied, framed, or
              exploited for commercial purposes without the express prior
              written consent of EZ Mobile DNA.
            </div>
            <br />
            <br />
            <div>
              <h3>Prices, Payment, and Delivery:</h3>
              <br />
              (a) The charges for the Service payable to Genesis DNA Labs are
              outlined in the current price list(s) available on the Site or as
              quoted in writing Genesis DNA Labs. Genesis DNA Labs is not liable
              for any pricing or typographical errors on the Site or in related
              publications.
              <br />
              (b) The Genesis DNA Labs reserves the right to adjust prices for
              the Service due to increased labor or material costs or delays
              affecting the Genesis DNA Labs’s costs in providing the Service.
              You will be informed of any price changes and have the option to
              proceed or cancel within seven days of notification.
              <br />
              (c) Payment for the Service must be received by Genesis DNA Labs
              before your order is accepted, including testing and result
              issuance. Upon opting for online access, you will receive
              confirmation, including your case reference number and Service
              details. Your order acceptance by Genesis DNA Labs forms a legally
              binding contract. Genesis DNA Labs may choose not to fulfill
              orders at its discretion. Unless otherwise arranged, full payment
              is required before test results are released.
              <br />
              (d) You are responsible for all transaction taxes applicable to
              your purchases.
              <br />
              (e) You confirm that all details provided to Genesis DNA Labs or
              purchasing the Service are accurate, and you have the necessary
              funds or credit for payment. Genesis DNA Labs may verify your
              payment details before providing the Service.
              <br />
              (f) Payment processing will be handled by the Company’s
              third-party processor using your preferred payment method or
              another designated method. The Company may charge an alternate
              payment method if the primary card is declined.
              <br />
              (g) Charges for completed and delivered orders are final unless
              the Company decides otherwise, at its discretion and in accordance
              with these Terms.
            </div>
            <br />
            <br />
            <div>
              <h3>Cancellation/Termination, Refunds & Charges Arising:</h3>
              <br />
              (a) As the paying party for the Service, you have a
              three-business-day window (excluding Saturdays, Sundays, and U.S.
              Bank Holidays) starting from the date the Company accepts your
              order under clause 7(c) to cancel the Contract without cause or
              penalty. This requires serving a written or emailed notice of
              cancellation to the Company. However, if Kits have been
              dispatched, appointments scheduled, samples collected, or the test
              conducted, a non-refundable deposit and any cancellation fees will
              apply and be retained.
              <br />
              (b) Upon cancellation of the Contract as per Section 8, the
              purchase price for the Service will be refunded to you as soon as
              reasonably possible. The Company will make reasonable efforts to
              process the refund within 30 days of receiving your cancellation
              notice.
              <br />
              (c) If Kits are damaged upon delivery, the Company may, at its
              discretion, replace or exchange the Kits or provide a refund equal
              to the price paid for the Service.
              <br />
              (d) The Company reserves the right to cancel or decline any order
              at any stage of the ordering process, including after submission
              and confirmation, for reasons such as limited product quantities,
              inaccuracies in product/pricing information, or payment issues.
              <br />
              (e) Any dates provided by the Company for Service provision are
              approximate and do not form a contractual obligation, nor are they
              considered essential to the Contract. The Company is not liable
              for any direct or indirect losses or damages resulting from
              Service delays.
              <br />
              (f) In rare instances where the analysis result for the purchased
              Service is erroneous, the Company may offer a free re-analysis or
              refund for the Service at its discretion.
            </div>
            <br />
            <br />
            <div>
              <h3>Non-Refundable Affiliate Fees and Promotion Disclaimer</h3>
              <br />
              All affiliate fees paid to Genesis DNA Labs are non-refundable
              under any circumstances. Additionally, Genesis DNA Labs is not
              required or obligated to promote or facilitate sales for the
              partner's business. While we may offer guidance and sales calls,
              Genesis DNA Labs is not responsible for the success of any
              partner’s business. Any promotional efforts undertaken by Genesis
              DNA Labs will be at its sole discretion.
            </div>
            <br />
            <br />
            <div>
              <h3>Your Responsibilities:</h3>
              <br />
              (a) By purchasing a Service, you agree to provide accurate
              biological samples as instructed in the Kits provided by us.
              Obtaining a reliable test result depends on collecting a quality
              sample following the provided instructions diligently. You affirm
              that any samples you provide to the Company are yours, or if
              provided on behalf of another person with legal authorization,
              that they belong to that person.
              <br />
              (b) It is recommended that you seek independent legal advice
              regarding your rights to obtain and use biological samples from
              individuals other than yourself. You certify that you have legal
              possession of the samples provided to the Company.
              <br />
              (c) You must be of legal age to enter a binding contract with the
              Company and not barred from receiving Services under applicable
              laws to use the Services and accept these Conditions.
              <br />
              (d) You confirm that you are mentally sound and capable of
              understanding the nature and purpose of the Service you are
              purchasing.
              <br />
              (e) You agree to comply with all relevant laws and regulations
              governing your access and use of the Site and the purchased
              Service.
              <br />
              (f) You certify that you are not an insurance company or employer
              seeking information about an insured person or employee.
              <br />
              (g) You acknowledge that information from the Service may be
              unexpected or emotionally impactful.
              <br />
              (h) You understand and accept that you have no rights to any
              research or commercial products resulting from your sample
              submission and will not receive compensation for such products.
              <br />
              (i) You warrant that you will not use the Service information,
              whether in whole or part, or combine it with other databases, for
              discriminatory or illegal purposes.
              <br />
              Account Creation, Customer Account, Passwords, and Security
              <br />
              (a) In order to access and download test results from the Service,
              you must create an account on the Site. By using the Site and
              Service, you agree to: (i) provide accurate, current, and complete
              registration information about yourself as prompted by the Site;
              and (ii) keep your registration information updated. Failure to
              provide accurate and current information, or if there is
              reasonable suspicion of inaccurate or incomplete information, may
              result in the suspension or termination of your account by the
              Company, and may also lead to the refusal of any current or future
              use of the Service or the Site.
              <br />
              (b) You are the only authorized user of the account you create on
              the Site, and you are fully responsible for all activities
              conducted under your account. You may not permit others to use
              your account credentials, nor can you transfer or assign your
              account to another person or entity. If you suspect unauthorized
              access to your account, you must promptly notify us using the
              contact details provided at the end of these Terms. The Company is
              not liable for any losses, damages, liabilities, expenses, or fees
              incurred due to unauthorized use of your account, even if you have
              informed us of such unauthorized access. You acknowledge and agree
              that we may request information to confirm your identity to
              maintain the security of your account.
              <br />
              (c) Upon purchasing a Service, you may receive your test results
              via email. It is your responsibility to maintain the
              confidentiality of your test results. Allowing third parties
              access to your test results may result in liability, costs, or
              damages for which you are responsible. You agree to indemnify and
              hold the Company harmless against any claims or suits by third
              parties related to unauthorized access to your test results.
              Promptly notify the Company of any unauthorized use of your test
              results. The Company is not liable for any losses or damages
              resulting from your failure to comply with this provision.
            </div>
            <br />
            <br />
            <div>
              <h3>Privacy and Disclosure:</h3>
              <br />
              We handle personally identifiable information submitted in
              connection with the Site or Service according to our Privacy
              Policy, which we recommend reading before using the Site or
              Service.
            </div>
            <br />
            <br />
            <div>
              <h3>Restrictions:</h3>
              <br />
              (a) You are prohibited from attempting to breach the security of
              the Site, including: (a) accessing data not meant for you or
              logging into a server or account without authorization; (b)
              attempting to test system vulnerabilities without permission; (c)
              unauthorized access or use of the Site; or (d) introducing
              malicious software or harmful material.
              <br />
              (b) Additionally, you agree not to:
              <br />
              Violate any applicable laws or regulations while using the Site.
              <br />
              Engage in conduct that disrupts or limits others' use or enjoyment
              of the Site.
              <br />
              Use the Site in a way that could damage or impair its
              functionality.
              <br />
              Use automated tools or processes to access or monitor the Site
              without our consent.
              <br />
              Interfere with the proper functioning of the Site through any
              means.
              <br />
              Attempt to disrupt the Site's operations in any way.
              <br />
              Except for personally identifiable information collected according
              to our Privacy Policy, all materials, information, or other
              communications you transmit, upload, or post to the Site,
              including ideas, comments, suggestions, feedback, data, or similar
              content ("User Generated Content"), will be treated as
              non-confidential and non-proprietary. Genesis DNA Labs assumes no
              obligations regarding User Generated Content. Additionally, you
              assign all intellectual property rights in any User Generated
              Content to the Genesis DNA Labs. By submitting User Generated
              Content, you agree that Genesis DNA Labs is free to use it without
              limitation, compensation, and for any purpose, in identifiable or
              de-identifiable form.
            </div>
            <br />
            <br />
            <div>
              <h3>Links to Other Websites</h3>
              <br />
              The Site may include links to third-party websites. Genesis DNA
              Labs does not control or endorse the availability, content, or
              accuracy of these external sites. Clicking on such links is at
              your own risk, and Genesis DNA Labs is not liable for any losses
              or damages related to third-party websites.
              <br />
              General Disclaimer; Limitation of Liability
              <br />
              (a) We do not guarantee uninterrupted or error-free access to the
              Site or that defects will be corrected. The Service is provided
              "as is" and "as available," and the Genesis DNA Labs makes no
              warranties regarding the Site's operation, information accuracy,
              or Service performance.
              <br />
              (b) The Genesis DNA Labs does not guarantee that test results will
              be accepted by specific entities and advises seeking independent
              legal advice for using results in legal proceedings.
              <br />
              (c) To the extent permitted by law, Genesis DNA Labs, its
              officers, directors, employees, agents, or representatives are not
              liable for direct, indirect, special, punitive, exemplary, or
              consequential damages arising from the Service, Site access, or
              use, whether based on warranty, contract, tort (including
              negligence), strict liability, violation of statute, or otherwise.
              <br />
              (d) Your sole remedy for dissatisfaction with the Site, content,
              or Service is to cease using them. If certain limitations of
              liability are unenforceable, our maximum liability is $150 or the
              amount paid for the Service, whichever is less, unless prohibited
              by applicable law.
              <br />
              (e) Any claims related to Site or Service use must be filed within
              one year of the event giving rise to the claim.
              <br />
              (f) These exclusions and limitations do not affect consumer rights
              under local law or liability for death or personal injury caused
              by negligence.
            </div>
            <br />
            <br />
            <div>
              <h3>Copyright and Monitoring</h3>
              <br />
              The materials, including information, files, documents, text,
              photographs, and images found on the Site, are owned by the
              Genesis DNA Labs and our licensors, protected by U.S. and
              international copyright laws, and other intellectual property
              rights. Genesis DNA Labs or its licensors hold these rights,
              including trademarks, service marks, or trading names mentioned on
              the Site. You are allowed to download materials from the Site
              solely for placing an order with Genesis DNA Labs and to save and
              print a copy of these Conditions. However, you are not permitted
              to modify, copy, reproduce, republish, upload, post, transmit,
              distribute, or use any material or information from the Genesis
              DNA Labs’s website without the prior written consent of Genesis
              DNA Labs, except where expressly authorized, such as to complete
              tests or questionnaires.
              <br />
              All rights not explicitly granted herein are reserved to us and
              our licensors.
            </div>
            <br />
            <br />
            <div>
              <h3>Third Party Rights</h3>
              <br />
              These Conditions do not grant any third-party beneficiary rights
              or any rights of any kind to third parties, except for the Genesis
              DNA Labs affiliates, directors, employees, or representatives.
              Third parties not party to these Conditions have no rights to
              enforce any term of this Contract under the UK Contracts (Rights
              of Third Parties) Act 1999.
              <br />
              Agreement to Arbitrate
              <br />
              By using the Site and Services, you and Genesis DNA Labs agree to
              resolve all covered disputes through confidential and binding
              arbitration. Covered disputes include any disputes or claims
              between the parties, irrespective of when they arose, and all
              other claims or disputes under various laws, statutes, contracts,
              torts, or common law. Arbitration will be conducted under the JAMS
              Streamlined or Comprehensive Arbitration Rules and Procedures,
              with the arbitrator deciding all issues. The parties waive the
              right to class, collective, or joint actions and agree to share
              arbitration costs. The parties agree to abide by the arbitrator’s
              decision, and the award shall be final and binding.
            </div>
            <br />
            <br />
            <div>
              <h3>General</h3>
              <br />
              These Conditions constitute the entire agreement between you and
              Genesis DNA Labs superseding any prior agreements. Any notices to
              Genesis DNA Labs should be sent via email to
              genesisdnalabs@gmail.com. Genesis DNA Labs is not liable for
              failures beyond its control, and any unenforceable provisions in
              these Conditions will be replaced with enforceable ones. Waiver of
              any provision Genesis DNA Labs does not constitute a waiver of any
              other provision or right.
            </div>
          </Container>
        </>
      }
    />
  );
}
