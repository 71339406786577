import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import Accordion from 'react-bootstrap/Accordion';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Spinner } from 'react-bootstrap';
import { db } from '../../firebase.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function AccordianServiceList() {
    const [serviceList, setServiceList] = useState([]);
    const [loading, setLoading] = useState(false);

    const url = "https://calendly.com/genesisdnalabs/appointment-form";

    const fetchServices = async () => {
        setLoading(true);

        try {
            const querySnapshot = await getDocs(collection(db, "services"));
            const services = [];
            querySnapshot.forEach((doc) => {
                services.push({ id: doc.id, ...doc.data() });
            });

            // Filter out inactive services and reorder to put "Paternity Testing" first
            const filteredAndReorderedServices = reorderAndFilterServices(services);

            setServiceList(filteredAndReorderedServices);
            console.log(filteredAndReorderedServices);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const reorderAndFilterServices = (services) => {
        const activeServices = services.filter(service => service.active);
        const paternityTesting = activeServices.find(service => service.name === "Paternity Testing");
        const otherServices = activeServices.filter(service => service.name !== "Paternity Testing");
        return [paternityTesting, ...otherServices];
    };

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <>
            {loading && !serviceList.length ? 
                <Spinner animation="border" />
                : 
                serviceList.length > 0 && (
                    <Accordion>
                        {serviceList.map((service, index) => (
                            <AccordionItem eventKey={index.toString()} key={service.id}>
                                <AccordionHeader>{service.name}</AccordionHeader>
                                <AccordionBody>
                                    <p>{service.desc}</p>
                                    <p><span><Link to={`/services/${service.id}`} className='service_link'>Learn More <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: '2.5px'}} className='font-awesome' /></Link></span></p>
                                    <p style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Price: ${service.price}</p>
                                    <Link to='/schedule'><Button className='mt-2'>Book Service Now</Button></Link>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )
            }
        </>
    );
}
