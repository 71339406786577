import React from 'react'

import Navigation from '../nav/Navigation.js'

import { Container } from 'react-bootstrap'
import Banner from '../banner/Banner.js'
import Footer from '../footer/Footer.js'


export default function Layout(props) {
  return (
    <>
    <Container className='w-100 p-0' fluid style={{minHeight: '100vh'}}>
        <Navigation />
        <Container fluid>
          <Banner image={props.image} header={props.header} subheader={props.subheader} />
          <Container align='center' fluid style={{marginBottom: '100px'}}>
              {props.content}
          </Container>
        </Container>
        <Footer />
    </Container>
    </>
  )
}
