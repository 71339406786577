import React from 'react'
import {Helmet} from "react-helmet";

export default function SEOHelmet(props) {
  return (
    <Helmet>
        <meta charSet="utf-8" />
        <title>Genesis DNA Labs | {props.page}</title>
        <link rel="canonical" href={`http://genesisdnalabs.com/${props.link}`} />
        <meta name="description" content={props.content} />
    </Helmet>
  )
}
