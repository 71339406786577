import React from 'react'
import Layout from '../components/layout/Layout'

import image from '../assets/images/banner-5.jpg'
import image2 from '../assets/images/spare-1.jpg'
import image3 from '../assets/images/spare-2.jpg'

import { Container, Row, Col, CardBody, CardHeader, CardText, Button, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'react-bootstrap'
import AccordianServiceList from '../components/services/AccordianServiceList'
import SEOHelmet from '../components/seo-helmet/SEOHelmet'

import { motion } from 'framer-motion';




export default function Home() {
  const benefits = [
    {
        heading: 'Confidentiality Assured',
        description: "Your privacy is our top priority. Rest easy knowing that your genetic information is handled with the utmost confidentiality and in compliance with industry standards.",
    },
    {
        heading: 'Accurate and Reliable Results',
        description: "Our state-of-the-art testing methods ensure precise and dependable results, giving you peace of mind and clarity.",
    },
        {
            heading: 'Convenience At Your Fingertips',
            description: "We understand that your time is valuable. With Genesis DNA Labs, there's no need to travel to a testing facility. Our team of experienced professionals comes to you, ensuring a hassle-free and comfortable experience.",
        },
        
        
    ]

    const riseUpAnimation = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.5 }
    };

    const url = "https://calendly.com/genesisdnalabs/appointment-form"

  return (
    <Layout 
        image={image}
        header={"Unlock and reveal the secrets within your DNA."}
        subheader={"Welcome to Genesis DNA Labs"}
        content={
            <>
            <SEOHelmet 
                page="Home" 
                link=""
                content="Welcome to Genesis DNA Labs, your premier destination for discreet and efficient DNA testing."
            />
            {/*}
            <Container>
                <div style={{paddingTop: '100px'}}>
                    <Row>
                        <Col className='p-2'>
                            <div>
                                <h2 className='big-heading'>Specializing In Mobile DNA Testing</h2>
                                <p>Genesis DNA Labs is dedicated to providing safe and efficient DNA testing at your convience. Our team proudly serves the Dallas/Fort Worth Metroplex.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
{*/}
            <Container className='section d-flex justify-content-center align-items-center'>
            <Row>
                {benefits.map((i, index) => {
                    return (
                        
                            <Col lg={4} className='p-2'>
                                <motion.div
                            key={index}
                            initial={riseUpAnimation.initial}
                            animate={riseUpAnimation.animate}
                            transition={{ ...riseUpAnimation.transition, delay: index * 0.2 }}
                        >
                                <CardBody>
                                    <CardHeader>
                                        <h3>{i.heading}</h3>
                                    </CardHeader>
                                    <CardText>{i.description}</CardText>
                                </CardBody>
                                </motion.div>
                            </Col>
                    );
                })}
            </Row>

            </Container>
            <Container className='section'>
                <Row>
                    <Col lg={6} className='p-2'>
                        <div>
                            <h2 className='big-heading'>Our Services</h2>
                        </div>
                        <div>
                            <img src={image2} className='section-image' />
                        </div>
                    </Col>
                    <Col lg={6} className='p-2'>
                        <AccordianServiceList />
                    </Col>
                </Row>
            </Container>
            <Container className='section'>
                <div style={{paddingTop: '100px'}}>
                <Row>
                <Col lg={6} className='p-2'>
                    <div>
                <h2 className='big-heading'>Testing You Can Trust</h2>
                </div>
                <div>
                            <img src={image3} className='section-image' />
                        </div>
                </Col>
                <Col id="scheduling-div" lg={6} className='p-2'>
                    <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <div>
                    <h2>Same Day Mobile Appointments Available</h2>
                    </div>
                    <div>
                    <p>Ready For Results? Book Your Test Today!</p>
                    <a href={url}>
                        <Button>Book Now</Button>
                    </a>
                    </div>
                    </div>
                </Col>
                </Row>
                </div>
            </Container>
            <Container className='section'>
                <Row style={{paddingTop: '100px'}}>
                    <Col lg={6} className='p-2'>
                <h2 className='big-heading'>Contact Us</h2>
                </Col>
                <Col lg={6} className='p-2'>
                <Accordion defaultActiveKey="0">
                    <AccordionItem eventKey='0'>
                        <AccordionHeader>Headquarters</AccordionHeader>
                        <AccordionBody>Frisco, Texas</AccordionBody>
                    </AccordionItem>
                    <AccordionItem eventKey='1'>
                        <AccordionHeader>Support</AccordionHeader>
                        <AccordionBody>
                            <p>Appointment Hours: Monday - Friday, 9AM-8PM CST</p>
                            <ul>
                                <li>Weekend Appointments Available By Request</li>
                                <li>Earlier or later appointments available by request</li>
                            </ul>
                            <p className='p-0 m-0'><span style={{fontWeight: 'bolder'}}>Email:</span> genesisdnalabs@gmail.com</p>
                            <p className='p-0 m-0'><span style={{fontWeight: 'bolder'}}>Phone #:</span> 469-772-0201</p>
                            <p className='p-0 m-0'><span style={{fontWeight: 'bolder'}}>Address:</span> 
                                <br />
10601 Clarence St. Ste. 250
<br />
Frisco, TX 75033-3867</p>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
                </Col>
                </Row>
            </Container>
            </>
        }
    />
  )
}
