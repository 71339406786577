import React from 'react'

import Layout from '../components/layout/Layout'

import image from "../assets/images/banner-3.jpg"
import StandardServiceList from '../components/services/StandardServiceList'
import SEOHelmet from '../components/seo-helmet/SEOHelmet'
import { Container } from 'react-bootstrap'

export default function Services() {
  return (
    <Layout 
        image={image}
        header={"Services"}
        subheader={"We're here to help you in anyway we can."}
        content={
            <>
            <SEOHelmet 
                page="Services" 
                link="services"
                content="View the various DNA services offered by Genesis DNA Labs."
            />
            <Container>
                <div className='mb-5 mt-5'>
                    <h2>View Our Various Services</h2>
                    <p>We offer a variety of various DNA services. Check out all of our services below.</p>
                </div>
                <StandardServiceList />
            </Container>
            </>
        }
    />
  )
}
