import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// react router dom
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";


// pages
import Home from './pages/Home'; 
import About from './pages/About';
import Services from './pages/Services';
import Service from './pages/Service';
import Affiliates from './pages/Affiliates';
import Schedule from './pages/Schedule';
import TermsAndConditions from './pages/TermsAndConditions';
import Contact from './pages/Contact';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/services/:id' element={<Service />} />
        <Route path='/contact' element={<Contact />} />

        <Route path='/termsandconditions' element={<TermsAndConditions />} />
        <Route path='/schedule' element={<Schedule />} />
        {/*}
        <Route path='/affiliates' element={<Affiliates />} />
        
        {*/}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

