import React, { useState, useEffect } from 'react'
import { collection, getDocs } from "firebase/firestore";
import { CardBody, CardFooter, CardHeader, CardText, Spinner, Row, Col, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {db} from '../../firebase.js'
import { Link } from 'react-router-dom';


export default function StandardServiceList() {
    const [serviceList, setServiceList] = useState([])
    
    const [loading, setLoading] = useState(false);

    const url = "https://calendly.com/genesisdnalabs/appointment-form"

    const fetchServices = async () => {
        setLoading(true);

        try {
            const querySnapshot = await getDocs(collection(db, "services"));
            const services = [];
            await querySnapshot.forEach((doc) => {
                services.push({ id: doc.id, ...doc.data() });
            });
            const activeServices = services.filter(service => service.active);
            await setServiceList(activeServices);
            console.log(services)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchServices()
    }, [])

  return (
    <>
    {loading && !serviceList.length ? 
        <>
            <Spinner animation="border" />
        </> 
        : 
        <>
            <Row>
                {serviceList.map((service) => {
                    return(
                    <Col md={6}>
                        <CardBody>
                            <CardHeader>
                                <h3>{service.name}</h3>
                            </CardHeader>
                            <CardText>
                                {service.desc}
                                <br />
                                <span><Link to={`/services/${service.id}`} className='service_link'>Learn More <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: '2.5px'}} className='font-awesome' /></Link></span>
                            </CardText>
                            <CardFooter>
                                <p style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Price: ${service.price}</p>
                            </CardFooter>
                        </CardBody>
                    </Col>
                    )
                })}
            </Row>
            <Container>
                <div>
                    <h4 className='big-heading'>Ready To Get Started?</h4>
                    <p>We're here at your convience. Get started with your DNA test today.</p>
                </div>
                <div>
                    <Link to='/schedule'>
                        <Button>Get Started</Button>
                    </Link>
                </div>
            </Container>
        </>
    }
    </>
  )
}
