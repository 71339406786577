import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './index.css'

export default function Banner(props) {
  const url = "https://calendly.com/genesisdnalabs/appointment-form"

  return (
    <Card className="bg-dark text-white" id='banner-card'>
      <Card.Img src={props.image} alt="Card image" id='banner-image' />
      <Card.ImgOverlay className='d-flex align-items-center justify-content-center' style={{background: 'rgba(0, 0, 0, .4)', height: '100%'}}>
        <div align='center'>
        <Card.Title><h1 className='banner-heading'>{props.header}</h1></Card.Title>
        <Card.Text><h4>{props.subheader}</h4></Card.Text>
        <Link to='/schedule'><Button>Schedule Now</Button></Link>
        </div>
      </Card.ImgOverlay>
    </Card>
  )
}
