import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo-white.png'

import './index.css'

export default function Navigation() {
    const url = "https://calendly.com/genesisdnalabs/appointment-form"

  return (
    <Navbar fluid expand="lg" id='navigation-bar'>
        <Container fluid>
          <Navbar.Brand>
            <Link to='/' className='navigation-link' id='logo'>
                <img id='logo' src={logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav style={{marginLeft: 'auto'}}>
            <Nav.Link>
                <Link to='/about' className='navigation-link'>About</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='/services' className='navigation-link'>Services</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='/schedule' className='navigation-link'>Schedule</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='/contact' className='navigation-link'>Contact</Link>
            </Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
