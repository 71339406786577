import React from "react";
import Layout from "../components/layout/Layout";

import image from "../assets/images/banner-4.jpg";
import ContactForm from "../components/form/ContactForm";

import SEOHelmet from "../components/seo-helmet/SEOHelmet";

export default function Contact() {
  return (
    <Layout
      image={image}
      header={"Contact Us"}
      subheader={
        "Contact our team at Genesis DNA Labs today for any questions."
      }
      content={
        <>
          <SEOHelmet
            page="Contact"
            link="contact"
            content="Contact our team at Genesis DNA Labs today for any questions."
          />
          <div className="mt-2">
            <h3>Do you have questions or concerns?</h3>
            <p>
              If you have an questions or concerns, please reach out to us.
              We're here to help.
            </p>
          </div>
          <div>
            <p className="p-0 m-0"><span style={{fontWeight: 'bolder'}}>Email:</span> genesisdnalabs@gmail.com</p>
            <p className="p-0 m-0"><span style={{fontWeight: 'bolder'}}>Phone #:</span> 469-772-0201</p>
            <p>
            <span style={{fontWeight: 'bolder'}}>Address: </span>

              10601 Clarence St. Ste. 250
              <br />
              Frisco, TX 75033-3867
            </p>
          </div>
          <ContactForm />
        </>
      }
    />
  );
}
