import React from 'react';
import Layout from '../components/layout/Layout';

import image from '../assets/images/spare-4.jpg'
import { Container } from 'react-bootstrap';
import StandardServiceList from '../components/services/StandardServiceList';
import SEOHelmet from '../components/seo-helmet/SEOHelmet';

export default function About() {
  return (
    <Layout 
        image={image}
        header={"About Us"}
        subheader={"Learn more about Genesis DNA Labs and our mission."}
        content={
            <>
            <SEOHelmet 
                page="About" 
                link="about"
                content="Learn more about Genesis DNA Labs and our mission."
            />
            <Container>
              <div>
                <p>Welcome to Genesis DNA Labs, your trusted partner in comprehensive DNA testing services, proudly serving the greater Dallas-Fort Worth Metroplex. Located in Dallas, Texas, we specialize in mobile DNA testing, bringing convenience and reliability right to your doorstep. Our mission is to provide accurate and confidential DNA testing solutions, tailored to meet your specific needs, whether for personal knowledge, legal matters, or health insights.</p>
              </div>
              <br/>
              <br/>
              <div>
                <h3>Our Services</h3>
                <br />
                <p>At Genesis DNA Labs, we offer a wide range of DNA testing services designed to address various aspects of genetic analysis:</p>
                <br />
                <StandardServiceList />
              </div>
              <br />
              <br />
              <div>
                <h3>Our Unique Offering</h3>
                <br />
                <p>What sets Genesis DNA Labs apart is our commitment to convenience and quality. Our mobile DNA testing service ensures that you can get tested in the comfort of your own home or office. Our trained professionals will come to you, minimizing the hassle and providing a seamless experience. For those who prefer a more traditional approach, we also offer at-home testing kits that can be easily sent back to us via mail.</p>
              </div>
              <br />
              <br />
              <div>
                <h3>Pricing and Additional Services</h3>
                <br />
                <p>We understand that DNA testing can be complex, and costs can vary based on the specific requirements of each test. Our pricing is competitive and reflects the quality and accuracy of our services. Mobile collection services may add a premium for convenience, typically $50 - $100 extra per visit.</p>
                <br />
                <h4>Notes:</h4>
                <ul>
                  <li>
                    <h5>Pricing Variation:</h5>
                    <p>Prices can vary based on the complexity of the test, the lab used, and whether the test is for legal purposes or personal knowledge.</p>
                  </li>
                  <li>
                    <h5>Additional Services:</h5>
                    <p>Prices can vary based on the complexity of the test, the lab used, and whether the test is for legal purposes or personal knowledge.</p>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <div>
                <h3>Our Commitment</h3>
                <br />
                <p>At Genesis DNA Labs, we are dedicated to providing you with the highest standard of service. Our team of experts is here to guide you through the process, ensuring that you receive accurate results with the utmost confidentiality and care.</p>
                <br />
                <p>For more information or to schedule a mobile testing appointment, please contact us today. We look forward to serving you and helping you uncover the valuable insights hidden in your DNA.</p>
              </div>
            </Container>
            </>
        }
      />
  )
}
