import React from 'react'
import Layout from '../components/layout/Layout'

import image from '../assets/images/banner-2.jpg'
import { Container } from 'react-bootstrap'
import AcuityScheduler from '../components/scheduler/AcuityScheduler'

export default function Schedule() {
  return (
    <Layout 
        image={image}
        header={"Book Your Test Now"}
        subheader={"We're here at your convience."}
        content={
            <>
                <AcuityScheduler />
            </>
        }
    />
  )
}
