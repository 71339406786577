import React, { useState, useEffect } from 'react'
import { collection, getDocs } from "firebase/firestore";
import { Link, useParams } from 'react-router-dom';
import { db } from "../firebase"

import Layout from '../components/layout/Layout'
import image from "../assets/images/banner-6.jpg"
import SEOHelmet from '../components/seo-helmet/SEOHelmet'
import { Button, Container, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap'

export default function Service() {
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const fetchService = async () => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, "services"));
            const services = [];
            querySnapshot.forEach((doc) => {
                services.push({ id: doc.id, ...doc.data() });
            });
            const specificService = services.find(obj => obj.id === id);
            setService(specificService || null);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchService();
    }, [id]);

    // Render loading spinner
    if (loading) {
        return (
            <Layout image={image} header="Loading..." subheader="">
                <Spinner animation='border' />
            </Layout>
        );
    }

    // Handle case where service is not found
    if (!service) {
        return (
            <Layout image={image} header="Service Not Found" subheader="Please check the URL or contact support.">
                <p>The service you're looking for doesn't exist or an error occurred. Please check the URL or return to the services page.</p>
                <Button as={Link} to="/services">Back to Services</Button>
            </Layout>
        );
    }

    return (
        <Layout 
            image={image}
            header={service.name}
            subheader={service.desc || "We're here to help you in any way we can."}
            content={
                <>
                <SEOHelmet 
                    page={service.name}
                    link={`${service.name}/:id`}
                    content={service.desc || "We're here to help you in any way we can."}
                />
                <Container>
                    <div className='mb-5 mt-5'>
                        <h2>View Our {service.name}</h2>
                        <p>{service.desc}</p>
                    </div>
                    <div className='mb-5 mt-5'>
                        <h3>Description</h3>
                        <p>{service.long_desc}</p>
                    </div>
                    <div className='mb-5 mt-5'>
                        <h3>Benefits</h3>
                        <Container style={{ maxWidth: '40rem' }}>
                            <ul style={{listStyle: 'none', padding: '0px 0px', display: 'flex', flexDirection: 'column'}}>
                            {service.use_cases && service.use_cases.length > 0 ? 
                                service.use_cases.map((benefit, index) => (
                                    <ListGroup className='mt-1 mb-1' key={index}>
                                        <ListGroupItem>
                                            <span style={{fontWeight: 'bold'}}>{benefit}</span>
                                        </ListGroupItem> 
                                    </ListGroup>
                                )) 
                                : <p>No benefits available for this service.</p>
                            }
                            </ul>
                        </Container>
                    </div>
                </Container>
                </>
            }
        />
    );
}
